'use client'
import React, { useState, createContext } from 'react';

const keyDefault: KeyContextType = {
  key: '',
  setKey: () => {}
}
export const KeyContext = createContext<KeyContextType>(keyDefault);

// specify the props type twice on purpose
const MainComponent: React.FC<{children: React.ReactNode}> = ({ children } : { children: React.ReactNode}) => {
  const [key, setKey] = useState('');

  return (
    <KeyContext.Provider value={{ key, setKey }}>
      {children}
    </KeyContext.Provider>
  );
};

export default MainComponent;